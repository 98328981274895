import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useRef, useState } from "react";
import { Link, Typography } from "@mui/material";
import MomentUtils from '@date-io/moment';

export const LOADING                = 'loading';
export const USER                   = 'user';
export const PAGE_SIZE              = 15;
export const ROWS_PER_PAGE_OPTIONS	= [ 15 ];
export const BACKGROUND_COLOR		= '#000000';
export const BACKGROUND_COLOR_ALT	= '#231f20';
export const GRAY_COLOR				= '#5c5c5c';
export const WHITE_COLOR			= '#ffffff';
export const RED_COLOR				= '#da291c';
export const PRIMARY_COLOR			= '#1daccc';

export const OK                     = 'OK';
export const YES                    = 'Yes';
export const NO                     = 'No';

export const DELETE_RECORD          = 'Delete record';
export const ARE_YOU_SURE_DELETE    = 'Are you sure you want to delete this record?';

export const OPERATION_SUCCESSFUL   = 'Operation Successful';
export const SUCCESSFULLY_DELETED   = 'Record successfully deleted.';
export const SUCCESSFULLY_ADDED     = 'Record successfully added.';
export const SUCCESSFULLY_UPDATED   = 'Record successfully updated.';

export const OPERATION_FAILED       = 'Operation Failed';
export const UNSUCCESSFULLY_DELETED = 'Unable to delete record.';
export const THERE_WAS_A_PROBLEM	= 'There was an problem saving your data, please try again.';

export function camelCase( str )
{
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

export function renderTableCheckmark( isChecked )
{
    if( !isChecked ){ return <div />; } return <DoneOutlinedIcon style={ { color: WHITE_COLOR } } />;
}

export const onRenderBlankFiller = ( params ) => { return <div/>; };

export const dateFormatter = ( value ) =>
{
    if( value == null ){ return ''; }

    return new Date( value ).toLocaleDateString( "en-US" );
};

export const timeFormatter = ( value ) =>
{
    if( value == null ){ return ''; }

    return new Date( value ).toLocaleTimeString( "en-US" );
};

export const timeFormatterAlt = ( value ) =>
{
    if( !value ){ return ''; }

    const moment    = new MomentUtils();
    const date      = moment.date( value );

    return moment.formatByString( date, "h:mm A" );
};

export const getEpoch = ( value ) =>
{
    const moment	= new MomentUtils();
	const date		= moment.date( value );

    return date.unix();
};

export function presentDialogSuccess( dialogContext, message, callback )
{
    dialogContext.presentDialog( true, OPERATION_SUCCESSFUL, message, null, null, OK, callback, null, null );
}

export function presentDialogFailed( dialogContext, message, callback )
{
    dialogContext.presentDialog( true, OPERATION_FAILED, message, null, null, OK, callback, null, null );
}

export function buildSearchQuery( filterModelItems )
{
    let searchQuery = { columns: [], search: [] };

    for( let i = 0; i < filterModelItems.length; i++ )
    {
        const item = filterModelItems[i];

        if( item.value )
        {
            searchQuery.columns.push( item.columnField );
            searchQuery.search.push( item.value );
        }
    }

    return searchQuery;
}

export function buildTableSort( sortModelItems )
{
    let sort = [];

    for( let i = 0; i < sortModelItems.length; i++ )
    {
        const item = sortModelItems[i];

        sort.push( item.field + " " + item.sort );
    }

    return sort;
}

export function useAsyncReference( value, isProp = false )
{
    const ref               = useRef( value );
    const [, forceRender]   = useState( false );

    function updateState( newState )
    {
        if( !Object.is( ref.current, newState ) )
        {
            ref.current = newState;

            forceRender( s => !s );
        }
    }

    if( isProp )
    {
        ref.current = value;

        return ref;
    }

    return [ref, updateState];
}

export function Copyright()
{
    return (
        <Typography variant="body2" align="center">
            { '©' }
            { new Date().getFullYear() }
            { ' ' }
            <Link color="inherit" underline="always" href="https://www.goturbo.com" target="_blank">Go Turbo</Link>
        </Typography>
    );
}

export const formatPhoneNumber = ( phone_number ) =>
{
    if( ( phone_number === null ) || ( phone_number.length === 0 ) || ( phone_number.length !== 10 ) ){ return ''; }

    return `(${ phone_number.substring( 0, 3 ) }) ${ phone_number.substring( 3, 6 ) }-${ phone_number.substring( 6, 10 ) }`;
};
