import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import App from './App';
import theme from './theme';
import { FirebaseAppProvider } from 'reactfire';

const moment = require( 'moment' );

const firebaseConfig = {
	apiKey:				"AIzaSyDaXqtnxN2QZhGlyVPD1gVnJK9ATuRneSc",
	authDomain:			"go-turbo.firebaseapp.com",
	projectId:			"go-turbo",
	storageBucket:		"go-turbo.appspot.com",
	messagingSenderId:	"60142441370",
	appId:				"1:60142441370:web:13a854a76501f3aa472023",
	measurementId:		"G-XKM955D01G"
};

moment.suppressDeprecationWarnings = true;

ReactDOM.render(
	<FirebaseAppProvider firebaseConfig={ firebaseConfig }>
		<ThemeProvider theme={ theme }>
			<CssBaseline/>
			<App/>
		</ThemeProvider>
	</FirebaseAppProvider>,
	document.querySelector( '#root' ),
);
