import { useContext, useEffect, useRef, useState } from "react";
import {Paper} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import { ARE_YOU_SURE_DELETE, dateFormatter, DELETE_RECORD, formatPhoneNumber, NO, PAGE_SIZE, presentDialogFailed, presentDialogSuccess, ROWS_PER_PAGE_OPTIONS, SUCCESSFULLY_DELETED, timeFormatter, UNSUCCESSFULLY_DELETED, YES } from "../../constants";
import ButtonDelete from "../buttonDelete";
import {DialogContext} from "../../services/dialogService";
import { useFirestore } from "reactfire";
import ButtonEdit from "../buttonEdit";

const ServiceRequestListing = props =>
{
    const [rows, setRows]       	= useState( [] );
	const firestore					= useFirestore();
    const dialogContext         	= useContext( DialogContext );
	const onEditServiceRequest		= ( row ) => { props.onEditServiceRequest( row.id ); };
    const onDeleteServiceRequest	= ( row ) => { dialogContext.presentDialog( true, DELETE_RECORD, ARE_YOU_SURE_DELETE, NO, () => {}, YES, onDeleteServiceRequestPositive, { id: row.id }, null ); };
    const columns               	= [
										{ field: "edit", filterable: false, headerName: ' ', width: 60, align: 'center', disableColumnMenu: true, disableClickEventBubbling: true, renderCell: ( params ) => { return <ButtonEdit onClick={ () => onEditServiceRequest( params.row ) } /> ; } },
										{ field: "delete", filterable: false, headerName: ' ', width: 60, align: 'center', disableColumnMenu: true, disableClickEventBubbling: true, renderCell: ( params ) => { return <ButtonDelete onClick={ () => onDeleteServiceRequest( params.row ) } /> ; } },
										{ field: 'status_name', headerName: 'Status', width: 200 },
										{ field: 'tractor_id', headerName: 'Tractor', width: 200 },
										{ field: 'full_name', headerName: 'Full Name', width: 200 },
										{ field: 'call_back_phone', headerName: 'Call Back Phone', width: 200, valueFormatter: ( { value } ) => { return formatPhoneNumber( value ); } },
										{ field: 'date_time', headerName: 'Date / Time', width: 200, valueFormatter: ( { value } ) => { return dateFormatter( ( value * 1000 ) ) + ' ' + timeFormatter( ( value * 1000 ) ); } },
										{ field: 'issue', headerName: 'Issue', flex: 1 }
									];

	useEffect( () => { getServiceRequests(); }, [] );

	const getServiceRequests = () =>
	{
		firestore
			.collection( 'serviceRequests' )
			// .where( 'status_name', '!=', 'Archived' )
			.get()
			.then( ( results ) =>
			{
				let requests = [];

				for( let i = 0; i < results.docs.length; i++ )
				{
					const doc = results.docs[i].data();

					if( props.archived )
					{
						if( !doc.status_name ){ continue; }

						if( ( doc.status_name.localeCompare( 'Archive' ) !== 0 ) ||
							( doc.status_name.localeCompare( 'Archived' ) !== 0 ) )
						{
							continue;
						}
					}
					else
					{
						if( doc.status_name )
						{
							if( ( doc.status_name.localeCompare( 'Archive' ) === 0 ) ||
								( doc.status_name.localeCompare( 'Archived' ) === 0 ) )
							{
								continue;
							}
						}
					}

					requests.push( { ...doc, id: results.docs[i].id } );
				}

				requests.sort( ( a, b ) => ( a.date_time < b.date_time ) ? 1 : -1 );

				setRows( requests );

			} )
			.catch
			(
				function( error )
				{
					setRows( [] );
					console.log( error );
				}
			);
	};

	const onDeleteServiceRequestPositive = ( data ) =>
	{
		firestore
			.collection( 'serviceRequests' )
			.doc( data.id )
			.delete()
			.then( ( results ) =>
			{
				presentDialogSuccess( dialogContext, SUCCESSFULLY_DELETED, () => { getServiceRequests(); } );
			} )
			.catch
			(
				function( error )
				{
					presentDialogFailed( dialogContext, UNSUCCESSFULLY_DELETED, null );
				}
			);
	};

    return (
        <div style={ { height: 500, width: '100%', padding: 25 } }>
            <Paper style={ { height: '100%', width: '100%' } }>
                <DataGrid rows={ rows } columns={ columns } getRowId={ ( row ) => row.id } pageSize={ PAGE_SIZE } rowsPerPageOptions={ ROWS_PER_PAGE_OPTIONS } disableSelectionOnClick/>
            </Paper>
        </div>
    );
}

export default ServiceRequestListing;
