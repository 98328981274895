import { useContext, useEffect, useRef, useState } from "react";
import {Paper} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {StateContext} from "../../services/stateService";
import { PAGE_SIZE, ROWS_PER_PAGE_OPTIONS } from "../../constants";
import GeotabService from "../../services/geotabService";
import GroupPhoneNumbers from "./groupPhoneNumbers/groupPhoneNumbers";

const GroupListing = props =>
{
	const [ rows, setRows ]			= useState( [] );
	const [ groupId, setGroupId ]	= useState( null );
    const context					= useRef( useContext( StateContext ) );
    const columns					= [
										{ field: 'id', headerName: 'ID', width: 100 },
										{ field: 'name', headerName: 'Name', width: 350 },
										{ field: 'comments', headerName: 'Comments', flex: 1 }
									];

	useEffect( () =>
	{
		GeotabService( context.current ).getGroups( function( results )
		{
			let groups = [];

			for( let i = 0; i < results.length; i++ )
			{
				const group = results[i];

				if( !group.name ){ continue; }

				groups.push( group );
			}

			groups.sort( ( a, b ) => ( a.name > b.name ) ? 1 : -1 );

			setRows( groups );
		} );

	}, [] )

	const onSelectionModelChange = ( ids ) =>
	{
		setGroupId( ids[0] );
	};

    return (
    	<div>
        <div style={ { height: 500, width: '100%', padding: 25 } }>
            <Paper style={ { height: '100%', width: '100%' } }>
                <DataGrid rows={ rows } columns={ columns } getRowId={ ( row ) => row.id } pageSize={ PAGE_SIZE } rowsPerPageOptions={ ROWS_PER_PAGE_OPTIONS } onSelectionModelChange={ ( e ) => onSelectionModelChange( e ) }/>
            </Paper>
        </div>
		{ groupId &&
			<div style={ { padding: 25 } }>
				<GroupPhoneNumbers groupId={ groupId } />
			</div>
		}
		</div>
    );
}

export default GroupListing;
