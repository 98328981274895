import ButtonNeutral from "../buttonNeutral";
import { Formik, Form } from 'formik';
import { Paper } from "@mui/material";
import FieldText from "../fieldText";
import { useContext, useEffect, useState } from "react";
import { useFirestore } from "reactfire";
import ButtonPositive from "../buttonPositive";
import { OK, OPERATION_FAILED, OPERATION_SUCCESSFUL, SUCCESSFULLY_UPDATED, THERE_WAS_A_PROBLEM } from "../../constants";
import { DialogContext } from "../../services/dialogService";

const UserAddEdit = props =>
{
	const firestore			= useFirestore();
	const dialogContext		= useContext( DialogContext );
	const [user, setUser]	= useState( { } );
	const onCancel			= () => { props.onAddEditComplete(); };

	useEffect( () =>
	{
		if( !props.id ){ return; }

		firestore
			.collection( 'users' )
			.doc( props.id )
			.get()
			.then( ( results ) =>
			{
				if( results.exists )
				{
					setUser( results.data() );
				}

			} )
			.catch
			(
				function( error )
				{
					console.log( error );
				}
			);

	}, [ props.id ] )

	const onSubmit = ( values, actions ) =>
	{
		if( !props.id ){ return; }

		if( ( values.customer_name.length === 0 ) || ( values.full_name.length === 0 ) )
		{
			dialogContext.presentDialog( true, OPERATION_FAILED, 'All fields are required', null, null, OK, null, null, null );
			actions.setSubmitting( false );
			return;
		}

		firestore
			.collection( 'users' )
			.doc( props.id )
			.set( values, { merge: true } )
			.then( () =>
			{
				actions.setSubmitting( false );

				dialogContext.presentDialog( true, OPERATION_SUCCESSFUL, SUCCESSFULLY_UPDATED, null, null, OK, () =>
				{
					props.onAddEditComplete();
				}, null, null );

			} )
			.catch
			(
				function( error )
				{
					actions.setSubmitting( false );

					dialogContext.presentDialog( true, OPERATION_FAILED, THERE_WAS_A_PROBLEM, null, null, OK, null, null, null );

					console.log( error );
				}
			);
	};

	return (
		<div style={ { padding: 25 } }>
			<Paper style={ { padding: 25 } }>
				<Formik enableReinitialize initialValues={ user } onSubmit={ onSubmit }>
					{ ( { values, submitForm, isSubmitting, touched, errors, handleChange } ) => (
						<Form>
							<FieldText id="customer_name" label="Customer Name" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
							<FieldText id="full_name" label="Full Name" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
							<FieldText id="phone_number" label="Phone Number" values={ values } errors={ errors } touched={ touched } onChange={ handleChange } disabled={ true }/>
							<br/>
							<br/>
							<ButtonNeutral label="Cancel" disabled={ isSubmitting } onClick={ onCancel }/>
							<ButtonPositive label="Save" disabled={ isSubmitting } onClick={ submitForm }/>
						</Form>
					) }
				</Formik>
			</Paper>
		</div>
	);
}

export default UserAddEdit;
