import React from "react";
import {Paper, Tab, Tabs} from "@mui/material";
import GroupListing from "./groupListing";

export default function Groups()
{
    const [value, setValue] = React.useState( 0 );
    const [id, setId]       = React.useState( null );
    const handleChange      = ( event, newValue ) => { setId( null ); setValue( newValue ); };

    function tabProps( index )
    {
        return { id: `tab-${ index }` };
    }

    return (
        <Paper square>
            <Tabs value={ value } onChange={ handleChange }>
                <Tab label="Group Listing" { ...tabProps( 0 ) } />
            </Tabs>
            { value === 0 && <GroupListing /> }
        </Paper>
    );
}
