import ButtonNeutral from "../../buttonNeutral";
import ButtonPositive from "../../buttonPositive";
import { Formik, Form } from 'formik';
import { Paper } from "@mui/material";
import FieldText from "../../fieldText";
import FieldCheckboxWithLabel from "../../fieldCheckboxWithLabel";
import { useContext, useEffect, useState } from "react";
import { useFirestore } from "reactfire";
import { v4 as uuidv4 } from 'uuid';
import { DialogContext } from "../../../services/dialogService";
import { OK, OPERATION_FAILED, OPERATION_SUCCESSFUL, SUCCESSFULLY_ADDED, SUCCESSFULLY_UPDATED, THERE_WAS_A_PROBLEM } from "../../../constants";

const GroupPhoneNumberAddEdit = props =>
{
	const firestore									= useFirestore();
	const dialogContext								= useContext( DialogContext );
	const [groupPhoneNumber, setGroupPhoneNumber]   = useState( { } );
	const onCancel									= () => { props.onAddEditComplete(); };

	useEffect( () =>
	{
		if( !props.id ){ return; }

		firestore
			.collection( 'group_phone_numbers' )
			.doc( props.groupId )
			.get()
			.then( ( results ) =>
			{
				if( results.exists )
				{
					const phone_number = results.data()[props.id];

					setGroupPhoneNumber( phone_number );
				}

			} )
			.catch
			(
				function( error )
				{
					console.log( error );
				}
			);

	}, [ props.id ] )

	const onSubmit = ( values, actions ) =>
	{
		const phone_number = values.phone_number.replace( /\D/g, '' );

		if( ( phone_number.length === 0 ) || ( phone_number.length !== 10 ) )
		{
			dialogContext.presentDialog( true, OPERATION_FAILED, 'Invalid phone number, please enter a 10 digit number.', null, null, OK, null, null, null );
			actions.setSubmitting( false );
			return;
		}

		let record	= {};
		let uuid	= props.id === null ? uuidv4() : props.id;

		record[uuid] = values;

		firestore
			.collection( 'group_phone_numbers' )
			.doc( props.groupId )
			.set( record, { merge: true } )
			.then( () =>
			{
				actions.setSubmitting( false );

				let message = SUCCESSFULLY_ADDED;

				if( props.id )
				{
					message = SUCCESSFULLY_UPDATED;
				}

				dialogContext.presentDialog( true, OPERATION_SUCCESSFUL, message, null, null, OK, () => { props.onAddEditComplete(); }, null, null );

			} )
			.catch
			(
				function( error )
				{
					actions.setSubmitting( false );

					dialogContext.presentDialog( true, OPERATION_FAILED, THERE_WAS_A_PROBLEM, null, null, OK, null, null, null );

					console.log( error );
				}
			);
	};

	return (
		<div style={ { padding: 25 } }>
			<Paper style={ { padding: 25 } }>
				<Formik enableReinitialize initialValues={ groupPhoneNumber } onSubmit={ onSubmit }>
					{ ( { values, submitForm, isSubmitting, touched, errors, handleChange } ) => (
						<Form>
							<FieldText id="phone_number" label="Phone Number" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
							<br/>
							<FieldText id="phone_name" label="Full Name" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
							<br/>
							<FieldCheckboxWithLabel id="is_active" label="Is Active?" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
							<br/>
							<br/>
							<ButtonNeutral label="Cancel" disabled={ isSubmitting } onClick={ onCancel }/>
							<ButtonPositive label="Save" disabled={ isSubmitting } onClick={ submitForm }/>
						</Form>
					) }
				</Formik>
			</Paper>
		</div>
	);
}

export default GroupPhoneNumberAddEdit;
