import * as React from 'react';
import { styled } from '@mui/material/styles';
import { AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Close from '@mui/icons-material/Close';
import FieldAutocompleteNonForm from "./fieldAutocompleteNonForm";

export class GoTurboAppointmentForm extends React.PureComponent
{
	constructor( props )
	{
		super( props );

		this.state = { appointmentChanges: {} };

		this.getAppointmentData = () =>
		{
			const { appointmentData } = this.props;
			return appointmentData;
		};

		this.getAppointmentChanges = () =>
		{
			const { appointmentChanges } = this.state;
			return appointmentChanges;
		};

		this.changeAppointment = this.changeAppointment.bind( this );
		this.commitAppointment = this.commitAppointment.bind( this );
	}

	changeAppointment( { field, changes } )
	{
		const nextChanges = { ...this.getAppointmentChanges(), [field]: changes };

		this.setState( { appointmentChanges: nextChanges } );
	}

	changeAppointmentAccept( { field, changes } )
	{
		const nextChanges = { ...this.getAppointmentChanges(), [field]: changes };

		this.setState( { appointmentChanges: nextChanges } );
	}

	commitAppointment( type )
	{
		const { commitChanges }	= this.props;
		const appointment		= { ...this.getAppointmentData(), ...this.getAppointmentChanges() };

		if( type === 'deleted' )
		{
			commitChanges( { [type]: appointment.id } );
		}
		else if( type === 'changed' )
		{
			commitChanges( { [type]: { [appointment.id]: appointment } } );
		}
		else
		{
			commitChanges( { [type]: appointment } );
		}

		this.setState( { appointmentChanges: {} } );
	}

	render()
	{
		const { visible, visibleChange, appointmentData, cancelAppointment, target, onHide, technicians }	= this.props;
		const { appointmentChanges }																		= this.state;
		const displayAppointmentData																		= { ...appointmentData, ...appointmentChanges };
		const isNewAppointment																				= appointmentData.id === undefined;
		const applyChanges																					= isNewAppointment ? () => this.commitAppointment( 'added' ) : () => this.commitAppointment( 'changed' );

		const pickerEditorProps = field => ( {
			value:			displayAppointmentData[field],
			onChange:		() => {},
			onAccept:		date => this.changeAppointmentAccept( { field: [ field ], changes: date ? date.toDate() : new Date( displayAppointmentData[field] ) } ),
			ampm:			true,
			inputFormat:	'MM/DD/YYYY h:mm A',
			onError:		() => null
		} );

		const startDatePickerProps	= pickerEditorProps( 'startDate' );
		const endDatePickerProps	= pickerEditorProps( 'endDate' );

		const cancelChanges = () =>
		{
			this.setState( { appointmentChanges: {} } );

			visibleChange();
			cancelAppointment();
		};

		const PREFIX = 'GoTurbo';

		const classes = {
			content: `${ PREFIX }-content`,
			header: `${ PREFIX }-header`,
			closeButton: `${ PREFIX }-closeButton`,
			buttonGroup: `${ PREFIX }-buttonGroup`,
			button: `${ PREFIX }-button`,
			picker: `${ PREFIX }-picker`,
			wrapper: `${ PREFIX }-wrapper`,
			icon: `${ PREFIX }-icon`,
			textField: `${ PREFIX }-textField`,
			addButton: `${ PREFIX }-addButton`,
		};

		const StyledDiv = styled( 'div' )( ( { theme } ) => ({
			[`& .${ classes.icon }`]: {
				margin: theme.spacing( 2, 0 ),
				marginRight: theme.spacing( 2 ),
			},
			[`& .${ classes.header }`]: {
				overflow: 'hidden',
				paddingTop: theme.spacing( 0.5 ),
			},
			[`& .${ classes.textField }`]: {
				width: '100%',
			},
			[`& .${ classes.content }`]: {
				padding: theme.spacing( 2 ),
				paddingTop: 0,
			},
			[`& .${ classes.closeButton }`]: {
				float: 'right',
			},
			[`& .${ classes.picker }`]: {
				marginRight: theme.spacing( 2 ),
				'&:last-child': {
					marginRight: 0,
				},
				width: '50%',
			},
			[`& .${ classes.wrapper }`]: {
				display: 'flex',
				justifyContent: 'space-between',
				padding: theme.spacing( 1, 0 ),
			},
			[`& .${ classes.buttonGroup }`]: {
				display: 'flex',
				justifyContent: 'flex-end',
				padding: theme.spacing( 0, 2 ),
			},
			[`& .${ classes.button }`]: {
				marginLeft: theme.spacing( 2 ),
			},
		}) );

		const onTechnicianSelected = ( id, value ) =>
		{
			this.changeAppointment( { field: [ id ], changes: value } );
		};

		return (
			<AppointmentForm.Overlay visible={ visible } target={ target } onHide={ onHide } fullSize={ false }>
				<StyledDiv>
					<div className={ classes.header }>
						<IconButton className={ classes.closeButton } onClick={ cancelChanges } size="large">
							<Close color="action"/>
						</IconButton>
					</div>
					<div className={ classes.content }>
						<div className={ classes.wrapper }>
							<FieldAutocompleteNonForm id="technician_on_call"
													  name="technician_on_call"
													  label="Select Technician"
													  value={ displayAppointmentData['technician_on_call'] }
													  options={ technicians }
													  option_key="id"
													  option_name="name"
													  onChange={ onTechnicianSelected }/>
						</div>
						<div className={ classes.wrapper }>
							<LocalizationProvider dateAdapter={ AdapterMoment }>
								<DateTimePicker label="Start Date / Time" renderInput={ props => <TextField className={ classes.picker } { ...props } /> }{ ...startDatePickerProps }/>
								<DateTimePicker label="End Date / Time" renderInput={ props => <TextField className={ classes.picker } { ...props } /> }{ ...endDatePickerProps }/>
							</LocalizationProvider>
						</div>
					</div>
					<div className={ classes.buttonGroup }>
						{ !isNewAppointment && (
							<Button variant="outlined" color="secondary" className={ classes.button } onClick={ () => { visibleChange(); this.commitAppointment( 'deleted' ); } }>Delete</Button>
						) }
						<Button variant="outlined" color="primary" className={ classes.button } onClick={ () => { visibleChange(); applyChanges(); } }>{ isNewAppointment ? 'Add' : 'Save' }</Button>
					</div>
				</StyledDiv>
			</AppointmentForm.Overlay>
		);
	}
}
