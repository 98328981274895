import ButtonNeutral from "../buttonNeutral";
import { Formik, Form, Field } from 'formik';
import { Paper } from "@mui/material";
import FieldText from "../fieldText";
import { useContext, useEffect, useState } from "react";
import { useFirestore } from "reactfire";
import FieldTextArea from "../fieldTextArea";
import FieldAutocomplete from "../fieldAutocomplete";
import ButtonPositive from "../buttonPositive";
import { DialogContext } from "../../services/dialogService";
import { OK, OPERATION_FAILED, OPERATION_SUCCESSFUL, SUCCESSFULLY_UPDATED, THERE_WAS_A_PROBLEM } from "../../constants";

const ServiceRequestAddEdit = props =>
{
	const firestore								= useFirestore();
	const dialogContext							= useContext( DialogContext );
	const [serviceRequest, setServiceRequest]	= useState( { } );
	const [serviceStatuses, setServiceStatuses]	= useState( [] );
	const [initComplete, setInitComplete]		= useState( false );
	const onCancel								= () => { props.onAddEditComplete(); };

	useEffect( () =>
	{
		if( !props.id ){ return; }

		firestore
			.collection( 'serviceRequests' )
			.doc( props.id )
			.get()
			.then( ( results ) =>
			{
				if( results.exists )
				{
					setServiceRequest( results.data() );
					loadServiceStatuses();
				}

			} )
			.catch
			(
				function( error )
				{
					console.log( error );
				}
			);

	}, [ props.id ] )

	const loadServiceStatuses = () =>
	{
		firestore
			.collection( 'serviceStatuses' )
			.get()
			.then( ( results ) =>
			{
				let rows = [];

				for( let i = 0; i < results.docs.length; i++ )
				{
					const serviceStatus = results.docs[i].data();

					rows.push( { id: serviceStatus.status_name, name: serviceStatus.status_name } );
				}

				rows.sort( ( a, b ) => ( a.status_name > b.status_name ) ? 1 : -1 );

				setServiceStatuses( rows );
				setInitComplete( true );

			} )
			.catch
			(
				function( error )
				{
					setServiceStatuses( [] );
					console.log( error );
				}
			);
	};

	const onSubmit = ( values, actions ) =>
	{
		if( props.id )
		{
			firestore
				.collection( 'serviceRequests' )
				.doc( props.id )
				.set( values, { merge: true } )
				.then( () =>
				{
					actions.setSubmitting( false );

					dialogContext.presentDialog( true, OPERATION_SUCCESSFUL, SUCCESSFULLY_UPDATED, null, null, OK, () => { props.onAddEditComplete(); }, null, null );

				} )
				.catch
				(
					function( error )
					{
						actions.setSubmitting( false );

						dialogContext.presentDialog( true, OPERATION_FAILED, THERE_WAS_A_PROBLEM, null, null, OK, null, null, null );

						console.log( error );
					}
				);
		}
	};

	return (
		<div style={ { padding: 25 } }>
			<Paper style={ { padding: 25 } }>
				{ initComplete &&
					<Formik enableReinitialize initialValues={ serviceRequest } onSubmit={ onSubmit }>
						{ ( { values, submitForm, isSubmitting, touched, errors, handleChange } ) => (
							<Form>
								<Field component={ FieldAutocomplete } id="status_name" name="status_name" label="Select Service Status" options={ serviceStatuses } option_key="id" option_name="name" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
								<FieldText id="full_name" label="Full Name" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
								<FieldText id="call_back_phone" label="Call Back Phone" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
								<FieldText id="tractor_id" label="Tractor" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
								<FieldText id="issue" label="Issue" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
								<FieldTextArea id="issue_other" label="Issue (Other)" values={ values } errors={ errors } touched={ touched } onChange={ handleChange } style={ { margin: 5, width: '100%' } }/>
								<br/>
								<br/>
								<ButtonNeutral label="Cancel" disabled={ isSubmitting } onClick={ onCancel }/>
								<ButtonPositive label="Save" disabled={ isSubmitting } onClick={ submitForm }/>
							</Form>
						) }
					</Formik>
				}
			</Paper>
		</div>
	);
}

export default ServiceRequestAddEdit;
