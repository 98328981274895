import { useContext, useEffect, useRef, useState } from "react";
import {Paper} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {StateContext} from "../../../services/stateService";
import { ARE_YOU_SURE_DELETE, DELETE_RECORD, formatPhoneNumber, NO, PAGE_SIZE, presentDialogFailed, presentDialogSuccess, renderTableCheckmark, ROWS_PER_PAGE_OPTIONS, SUCCESSFULLY_DELETED, UNSUCCESSFULLY_DELETED, YES } from "../../../constants";
import { useFirestore } from "reactfire";
import ButtonEdit from "../../buttonEdit";
import ButtonDelete from "../../buttonDelete";
import { DialogContext } from "../../../services/dialogService";
import firebase from "firebase";

const GroupPhoneNumberListing = props =>
{
	const [ rows, setRows ]		= useState( [] );
    const context				= useRef( useContext( StateContext ) );
	const firestore				= useFirestore();
	const dialogContext			= useContext( DialogContext );
	const onRenderIsActive		= ( params ) => { return renderTableCheckmark( params.row.is_active ); };
	const onEditPhoneNumber		= ( row ) => { props.onEditGroupPhoneNumber( row.id ); };
    const onDeletePhoneNumber	= ( row ) => { dialogContext.presentDialog( true, DELETE_RECORD, ARE_YOU_SURE_DELETE, NO, () => {}, YES, onDeleteGroupPhoneNumberPositive, { id: row.id }, null ); };

    const columns				= [
    								{ field: "edit", headerName: ' ', width: 60, align: 'center', disableColumnMenu: true, disableClickEventBubbling: true, renderCell: ( params ) => { return <ButtonEdit onClick={ () => onEditPhoneNumber( params.row ) } /> ; } },
									{ field: "delete", headerName: ' ', width: 60, align: 'center', disableColumnMenu: true, disableClickEventBubbling: true, renderCell: ( params ) => { return <ButtonDelete onClick={ () => onDeletePhoneNumber( params.row ) } /> ; } },
									{ field: 'is_active', headerName: 'Active', headerAlign: 'center', width: 125, align: 'center', renderCell: onRenderIsActive },
									{ field: 'phone_name', headerName: 'Full Name', flex: 1 },
									{ field: 'phone_number', headerName: 'Phone Number', width: 225, valueFormatter: ( { value } ) => { return formatPhoneNumber( value ); } }
								];

	useEffect( () => { getGroupPhoneNumbers(); }, [ props.groupId ] )

	const getGroupPhoneNumbers = () =>
	{
		firestore
			.collection( 'group_phone_numbers' )
			.doc( props.groupId )
			.get()
			.then( ( results ) =>
			{
				if( !results.exists ){ setRows( [] ); return; }

				const	phone_numbers		= results.data();
				let		phone_number_rows	= [];

				for( const uuid in phone_numbers )
				{
					const phone_number = phone_numbers[uuid];

					phone_number_rows.push( { id: uuid, phone_number: phone_number.phone_number, is_active: phone_number.is_active, phone_name: phone_number.phone_name } );
				}

				phone_number_rows.sort( ( a, b ) => ( a.phone_number > b.phone_number ) ? 1 : -1 );

				setRows( phone_number_rows );

			} )
			.catch
			(
				function( error )
				{
					setRows( [] );
					console.log( error );
				}
			);
	};

	const onDeleteGroupPhoneNumberPositive = ( data ) =>
	{
		let record = {};

		record[data.id] = firebase.firestore.FieldValue.delete();

		firestore
			.collection( 'group_phone_numbers' )
			.doc( props.groupId )
			.update( record )
			.then( ( results ) =>
			{
				presentDialogSuccess( dialogContext, SUCCESSFULLY_DELETED, () => { getGroupPhoneNumbers(); } );
			} )
			.catch
			(
				function( error )
				{
					presentDialogFailed( dialogContext, UNSUCCESSFULLY_DELETED, null );
				}
			);
	};

    return (
        <div style={ { height: 500, width: '100%', padding: 25 } }>
            <Paper style={ { height: '100%', width: '100%' } }>
                <DataGrid rows={ rows } columns={ columns } getRowId={ ( row ) => row.id } pageSize={ PAGE_SIZE } rowsPerPageOptions={ ROWS_PER_PAGE_OPTIONS } disableSelectionOnClick={ true }/>
            </Paper>
        </div>
    );
}

export default GroupPhoneNumberListing;
