const ApiService = ( context ) =>
{
	const asyncPost = async ( url, data ) => { return new Promise(function( resolve, reject ){} ); };

	return {
		asyncPost
	};
};

export default ApiService;
