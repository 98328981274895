import React, { createContext } from 'react';
import DialogLoading from "../dialogs/dialogLoading";

export const StateContext = createContext( {

	loading:		false,
	credentials:	null,
	user:			null,

	update:			() => {},
	getUser:		() => {}
} );

export class StateProvider extends React.Component
{
	update = ( key, value, callback ) =>
	{
		this.setState
		(
			{ [key]: value },
			function()
			{
				if( callback ){ callback(); }
			}
		);
	};

	getUser = () => { return this.state.user; };

	state = {
		loading:		false,
		credentials:	null,
		user:			null,

		update:			this.update,
		getUser:		this.getUser
	};

	render() {
		return (
			<StateContext.Provider value={ this.state }>
				<DialogLoading open={ this.state.loading }/>
				{ this.props.children }
			</StateContext.Provider>
		);
	}
}
