import React, { useContext, useEffect, useRef, useState } from "react";
import { Paper } from "@mui/material";
import { Field, Form, Formik } from "formik";
import ButtonPositive from "../buttonPositive";
import { useFirestore } from "reactfire";
import { DialogContext } from "../../services/dialogService";
import { formatPhoneNumber, LOADING, OK, OPERATION_FAILED, OPERATION_SUCCESSFUL, THERE_WAS_A_PROBLEM } from "../../constants";
import FieldAutocomplete from "../fieldAutocomplete";
import { StateContext } from "../../services/stateService";

export default function Settings()
{
	const firestore							= useFirestore();
	const context               			= useRef( useContext( StateContext ) );
	const dialogContext						= useContext( DialogContext );
	const [settings, setSettings]			= useState( {} );
	const [technicians, setTechnicians]		= useState( [] );
	const [initComplete, setInitComplete]	= useState( false );

	useEffect( () => { loadSettings(); }, [] )

	const loadSettings = () =>
	{
		firestore
			.collection( 'settings' )
			.doc( '1' )
			.get()
			.then( ( results ) =>
			{
				if( results.exists )
				{
					setSettings( results.data() );

					loadTechnicians();
				}

			} )
			.catch
			(
				function( error )
				{
					console.log( error );
				}
			);
	};

	const loadTechnicians = () =>
	{
		context.current.update( LOADING, true );

		firestore
			.collection( 'technicians' )
			.get()
			.then( ( results ) =>
			{
				let rows = [];

				for( let i = 0; i < results.docs.length; i++ )
				{
					const technician = results.docs[i].data();

					rows.push( { id: technician.phone_number, name: technician.full_name + " - " + formatPhoneNumber( technician.phone_number ) } );
				}

				rows.sort( ( a, b ) => ( a.name > b.name ) ? 1 : -1 );

				setTechnicians( rows );
				setInitComplete( true );
				context.current.update( LOADING, false );

			} )
			.catch
			(
				function( error )
				{
					setTechnicians( [] );
					context.current.update( LOADING, false );
					console.log( error );
				}
			);
	};

	const onSubmit = ( values, actions ) =>
	{
		if( !values.technician_on_call )
		{
			onInvalidPhoneNumber( actions );
			return;
		}

		const phone_number = values.technician_on_call.replace( /\D/g, '' );

		if( ( phone_number.length === 0 ) || ( phone_number.length !== 10 ) )
		{
			onInvalidPhoneNumber( actions );
			return;
		}

		firestore
			.collection( 'settings' )
			.doc( '1' )
			.set( values, { merge: true } )
			.then( () =>
			{
				actions.setSubmitting( false );

				dialogContext.presentDialog( true, OPERATION_SUCCESSFUL, 'Settings successfully saved.', null, null, OK, () => { loadSettings(); }, null, null );

			} )
			.catch
			(
				function( error )
				{
					actions.setSubmitting( false );

					dialogContext.presentDialog( true, OPERATION_FAILED, THERE_WAS_A_PROBLEM, null, null, OK, null, null, null );

					console.log( error );
				}
			);
	};

	const onInvalidPhoneNumber = ( actions ) =>
	{
		dialogContext.presentDialog( true, OPERATION_FAILED, 'Invalid phone number, please enter a 10 digit number.', null, null, OK, null, null, null );

		actions.setSubmitting( false );
	};

	return (
		<Paper square>
			<div style={ { padding: 25 } }>
				<Paper style={ { padding: 25 } }>
					{ initComplete &&
						<Formik enableReinitialize initialValues={ settings } onSubmit={ onSubmit }>
							{ ( { values, submitForm, isSubmitting, touched, errors, handleChange } ) => (
								<Form>
									<Field component={ FieldAutocomplete } id="technician_on_call" name="technician_on_call" label="Select Technician On Call" options={ technicians } option_key="id" option_name="name" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
									<br/>
									<br/>
									<ButtonPositive label="Save" disabled={ isSubmitting } onClick={ submitForm }/>
								</Form>
							) }
						</Formik>
					}
				</Paper>
			</div>
		</Paper>
	);
}
