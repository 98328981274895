import { useContext, useEffect, useRef, useState } from "react";
import {Paper} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {StateContext} from "../../services/stateService";
import { ARE_YOU_SURE_DELETE, DELETE_RECORD, formatPhoneNumber, NO, PAGE_SIZE, presentDialogFailed, presentDialogSuccess, ROWS_PER_PAGE_OPTIONS, SUCCESSFULLY_DELETED, UNSUCCESSFULLY_DELETED, YES } from "../../constants";
import ButtonDelete from "../buttonDelete";
import ButtonEdit from "../buttonEdit";
import {DialogContext} from "../../services/dialogService";
import { useFirestore } from "reactfire";
import GeotabService from "../../services/geotabService";

const UserListing = props =>
{
    const [rows, setRows]       = useState( [] );
    const [groups, setGroups]	= useState( [] );
    const context               = useRef( useContext( StateContext ) );
	const firestore				= useFirestore();
    const dialogContext         = useContext( DialogContext );
    const onEditUser            = ( row ) => { props.onEditUser( row.id ); };
    const onDeleteUser          = ( row ) => { dialogContext.presentDialog( true, DELETE_RECORD, ARE_YOU_SURE_DELETE, NO, () => {}, YES, onDeleteUserPositive, { id: row.id }, null ); };
	const columns               = [
                                    { field: "edit", filterable: false, headerName: ' ', width: 60, align: 'center', disableColumnMenu: true, disableClickEventBubbling: true, renderCell: ( params ) => { return <ButtonEdit onClick={ () => onEditUser( params.row ) } /> ; } },
                                    { field: "delete", filterable: false, headerName: ' ', width: 60, align: 'center', disableColumnMenu: true, disableClickEventBubbling: true, renderCell: ( params ) => { return <ButtonDelete onClick={ () => onDeleteUser( params.row ) } /> ; } },
									{ field: 'customer_name', headerName: 'Customer Name', width: 200 },
									{ field: 'full_name', headerName: 'Full Name', width: 200 },
									{ field: 'phone_number', headerName: 'Phone Number', width: 200, valueFormatter: ( { value } ) => { return formatPhoneNumber( value ); } },
									{ field: 'group_name', headerName: 'Group', flex: 1 }
                                ];

	useEffect( () => { getGroups(); }, [] );

	useEffect( () =>
	{
		if( groups.length === 0 ){ return; }

		getUsers();

	}, [ groups ] );

	const getGroups = () =>
	{
		GeotabService( context.current )
			.getGroups( function( results )
			{
				let groups = [];

				for( let i = 0; i < results.length; i++ )
				{
					const group = results[i];

					if( !group.name ){ continue; }

					groups.push( group );
				}

				setGroups( groups );
			} );
	};

	const findGroup = ( id ) =>
	{
		for( let i = 0; i < groups.length; i++ )
		{
			const group = groups[i];

			if( group.id.localeCompare( id ) === 0 )
			{
				return group;
			}
		}
	};

	const getUsers = () =>
	{
		firestore
			.collection( 'users' )
			.get()
			.then( ( results ) =>
			{
				let users = [];

				for( let i = 0; i < results.docs.length; i++ )
				{
					const user	= results.docs[i].data();
					const group	= findGroup( user.group_id );

					if( group )
					{
						users.push( { ...user, id: results.docs[i].id, group_name: group.name } );
					}
				}

				users.sort( ( a, b ) => ( a.group_id > b.group_id ) ? 1 : -1 );

				setRows( users );

			} )
			.catch
			(
				function( error )
				{
					setRows( [] );
					console.log( error );
				}
			);
	};

	const onDeleteUserPositive = ( data ) =>
	{
		firestore
			.collection( 'users' )
			.doc( data.id )
			.delete()
			.then( ( results ) =>
			{
				presentDialogSuccess( dialogContext, SUCCESSFULLY_DELETED, () => { getUsers(); } );
			} )
			.catch
			(
				function( error )
				{
					presentDialogFailed( dialogContext, UNSUCCESSFULLY_DELETED, null );
				}
			);
	};

    return (
        <div style={ { height: 500, width: '100%', padding: 25 } }>
            <Paper style={ { height: '100%', width: '100%' } }>
                <DataGrid rows={ rows } columns={ columns } getRowId={ ( row ) => row.id } pageSize={ PAGE_SIZE } rowsPerPageOptions={ ROWS_PER_PAGE_OPTIONS } disableSelectionOnClick/>
            </Paper>
        </div>
    );
}

export default UserListing;
