import React, { useContext, useRef, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Avatar, Box, Container, CssBaseline, Typography } from "@mui/material";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { Formik, Form } from 'formik';
import { Copyright, LOADING } from "../constants";
import FieldText from "../components/fieldText";
import ButtonPositive from "../components/buttonPositive";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import { StateContext } from "../services/stateService";
import { useFirestore } from "reactfire";

const Index = props =>
{
	const firestore 									= useFirestore();
	const context										= useRef( useContext( StateContext ) );
	const [user]										= useState( { email_address: null, phone_number: null, password: null, verification_code: null } );
	const formRef										= useRef();
	const [confirmationResult, setConfirmationResult]	= useState( null );

    const useStyles = makeStyles( ( theme ) => ( {
		root: {
			marginTop:		theme.spacing( 4 ),
			display:		'flex',
			flexDirection:	'column',
			alignItems:		'center'
		}
	} ) );

    const classes = useStyles();

	const onSubmit = ( values, actions ) =>
	{
		actions.setSubmitting( false );

		if( values.verification_code )
		{
			onVerifyPress( values.verification_code, actions );
			return;
		}

		if( ( !values.email_address ) && ( !values.phone_number ) ){ return; }

		if( values.email_address )
		{
			firebase.auth()
				.signInWithEmailAndPassword( values.email_address, values.password )
				.then( ( userCredential ) =>
				{
					props.history.push( "/administration" );

				} )
				.catch( ( error ) =>
				{
					actions.setErrors( { 'email_address': 'Unable to authenticate user.', 'password': 'Unable to authenticate user.' } );
				} );
		}
		else if( values.phone_number )
		{
			const phone_number = values.phone_number.replace( /\D/g, '' );

			if( ( phone_number.length === 0 ) || ( phone_number.length !== 10 ) )
			{
				actions.setErrors( { 'phone_number': 'Invalid phone number, please enter a 10 digit number.' } );
				return;
			}

			firestore
				.collection( 'users' )
				.get()
				.then( ( results ) =>
				{
					let is_found = false;

					for( let i = 0; i < results.docs.length; i++ )
					{
						const user = results.docs[i].data();

						if( user.phone_number === phone_number )
						{
							is_found = true;
							break;
						}
					}

					if( !is_found )
					{
						actions.setErrors( { 'phone_number': 'Phone number not found, please contact customer support.' } );
						return;
					}

					signInWithPhoneNumber( `+1${ phone_number }`, actions );

				} )
				.catch
				(
					function( error )
					{
						context.current.update( LOADING, false );

						actions.setErrors( { 'phone_number': error.message } );
					}
				);
		}
	};

	const signInWithPhoneNumber = ( phone_number, actions ) =>
	{
		const applicationVerifier = new firebase.auth.RecaptchaVerifier( 'recaptcha-container', { 'size': 'invisible' } );

		firebase.auth().signInWithPhoneNumber( phone_number, applicationVerifier )
			.then
			(
				function( response )
				{
					context.current.update( LOADING, false );

					setConfirmationResult( response );
				}
			)
			.catch
			(
				function( error )
				{
					context.current.update( LOADING, false );

					actions.setErrors( { 'phone_number': error.message } );

					console.log( error );
				}
			);
	};

	const onVerifyPress = ( verification_code, actions ) =>
	{
		confirmationResult
			.confirm( verification_code )
			.then
				(
					function( response )
					{
						props.history.push( "/administration" );
					}
				)
			.catch
			(
				function( error )
				{
					// Sometimes there's an error the code is already used, see if it's a valid user anyway...
					if( error.includes( 'expired' ) )
					{
						props.history.push( "/" );
					}
					else
					{
						actions.setErrors( { 'verification_code': error.message } );
					}
				}
			);
	};

    return (
		<Container component="main" maxWidth="sm">
			<CssBaseline/>
			<div className={ classes.root }>
				<Box sm={ 4 } md={ 7 }>
					<img src={ '../images/goturbo-logo-rev.png' } alt="" />
				</Box>
				<Avatar className={ classes.avatar }>
					<LockOpenOutlinedIcon/>
				</Avatar>
				<Typography component="h1" variant="h5">Sign In</Typography>
				<Formik enableReinitialize initialValues={ user } onSubmit={ onSubmit } innerRef={ formRef }>
					{ ( { values, submitForm, isSubmitting, touched, errors, handleChange } ) => (
						<Form style={ { marginTop: 32, display: 'flex', flexDirection: 'column', alignItems: 'center' } }>
							{ !confirmationResult &&
								<>
									<FieldText id="email_address" label="Email Address" values={ values } errors={ errors } touched={ touched } onChange={ handleChange } size={ 'medium' }/>
									<FieldText id="password" label="Password" values={ values } type="password" errors={ errors } touched={ touched } onChange={ handleChange } size={ 'medium' }/>
									<p>OR</p>
									<FieldText id="phone_number" label="Phone Number" values={ values } errors={ errors } touched={ touched } onChange={ handleChange } size={ 'medium' }/>
									<div id='recaptcha-container' />
									<div style={ { marginTop: 32 } }>
										<ButtonPositive label="Login" disabled={ isSubmitting } onClick={ submitForm }/>
									</div>
								</>
							}

							{ confirmationResult &&
								<>
									<FieldText id="verification_code" label="Verification Code" values={ values } errors={ errors } touched={ touched } onChange={ handleChange } size={ 'medium' }/>
									<div style={ { marginTop: 32 } }>
										<ButtonPositive label="Continue" disabled={ isSubmitting } onClick={ submitForm }/>
									</div>
								</>
							}
						</Form>
					) }
				</Formik>
			</div>
			<Box mt={ 8 }>
				<Copyright/>
			</Box>
		</Container>
	);
}

export default withRouter( Index );
