import axios from 'axios';
import { LOADING } from '../constants';

const GeotabService = ( context ) =>
{
	const BASE_URL		= 'https://my243.geotab.com/apiv1';
	const api			= axios.create( { baseURL: BASE_URL, timeout: 10000, headers: { 'content-type': 'application/json', 'cache-control': 'no-cache' } } );
	const credentials	= { database: 'turbotruck', userName: 'bryan@vardeman.com', password: 'turbotruck' };

	const authenticate			= ( callback, error_callback ) => {
									post
									(
										'Authenticate',
										credentials,
										function( response )
										{
											context.update
											(
												'credentials',
												response.credentials,
												function()
												{
													if( callback ){ callback( response ); }
												}
											);
										},
										error_callback
									);
								};

	const getGroups				= ( callback, error_callback ) => {
									const params = { 'typeName' : 'Group', 'credentials': context.credentials };

									post( 'Get', params, callback, error_callback );
								};

	const getDevices			= ( groupId, callback, error_callback ) => {
									const params = { 'typeName' : 'Device', 'search': { 'groups': [ { 'id': groupId } ] }, 'credentials': context.credentials };

									post( 'Get', params, callback, error_callback );
								};

	const getDevicesStatusInfo	= ( groupId, callback, error_callback ) => {
									const params = { 'typeName' : 'DeviceStatusInfo', 'search': { 'deviceSearch': { 'groups': [ { 'id': groupId } ] } }, 'credentials': context.credentials };

									post( 'Get', params, callback, error_callback );
								};

	/* Private Methods */
	const post = ( method, params, callback, error_callback ) =>
	{
		context.update( LOADING, true );

		api.post( '/', { 'method': method, 'params': params } ).then( response => { handleCallback( response, callback, error_callback ); } ).catch( error => { handleCallback( error, null, error_callback ); } );
	};

	const handleCallback = ( response, callback, error_callback ) =>
	{
		context.update( LOADING, false );

		const data = response.data;

		if( data.error )
		{
			console.log( data.error.message );

			if( error_callback )
			{
				error_callback( data.error.message );
			}
		}
		else
		{
			if( callback )
			{
				callback( data.result );
			}
		}
	};

	return {
		authenticate,
		getGroups,
		getDevices,
		getDevicesStatusInfo
	};
};

export default GeotabService;
