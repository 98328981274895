import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material";
import { useFirestore } from "reactfire";
import { formatPhoneNumber, getEpoch, LOADING } from "../../constants";
import { StateContext } from "../../services/stateService";
import { EditingState, ViewState } from '@devexpress/dx-react-scheduler';
import {
	Scheduler,
	MonthView,
	Appointments,
	AppointmentTooltip,
	AppointmentForm,
	Toolbar,
	DateNavigator,
	TodayButton,
	ViewSwitcher,
	WeekView
} from '@devexpress/dx-react-scheduler-material-ui';
import { connectProps } from "@devexpress/dx-react-core";
import { GoTurboAppointmentForm } from "../goTurboAppointmentForm";

export default function Schedule()
{
	const firestore											= useFirestore();
	const context               							= useRef( useContext( StateContext ) );
	const [data, setData]									= useState( [] );
	const [currentDate, setCurrentDate]						= useState( '2022-06-01' );
	const [editingFormVisible, setEditingFormVisible]		= useState( false );
	const [confirmationVisible, setConfirmationVisible]		= useState( false );
	const [isNewAppointment, setIsNewAppointment]			= useState( false );
	const [editingAppointment, setEditingAppointment]		= useState( undefined );
	const [previousAppointment, setPreviousAppointment]		= useState( undefined );
	const [addedAppointment, setAddedAppointment]			= useState( {} );
	const [deletedAppointmentId, setDeletedAppointmentId]	= useState( undefined );
	const [technicians, setTechnicians]						= useState( [] );

	const onEditingAppointmentChange						= ( editingAppointment )	=> { setEditingAppointment( editingAppointment ); };
	const toggleEditingFormVisibility						= ()						=> { setEditingFormVisible( !editingFormVisible ); };
	const toggleConfirmationVisible							= ()						=> { setConfirmationVisible( !confirmationVisible ); };
	const currentDateChange									= ( newDate )				=> { setCurrentDate( newDate ); };

	useEffect( () => { loadTechnicians(); }, [] )

	const loadTechnicians = () =>
	{
		context.current.update( LOADING, true );

		firestore
			.collection( 'technicians' )
			.get()
			.then( ( results ) =>
			{
				let rows = [];

				for( let i = 0; i < results.docs.length; i++ )
				{
					const technician = results.docs[i].data();

					rows.push( { id: results.docs[i].id, phone_number: technician.phone_number, name: technician.full_name + " - " + formatPhoneNumber( technician.phone_number ) } );
				}

				rows.sort( ( a, b ) => ( a.name > b.name ) ? 1 : -1 );

				setTechnicians( rows );

				loadSchedule( rows );
			} )
			.catch
			(
				function( error )
				{
					setTechnicians( [] );
					context.current.update( LOADING, false );
					console.log( error );
				}
			);
	};

	const findTechnician = ( technician_data, id ) =>
	{
		let found_technician = null;

		for( let i = 0; i < technician_data.length; i++ )
		{
			if( technician_data[i].id === id )
			{
				found_technician = technician_data[i];
				break;
			}
		}

		return found_technician;
	};

	const loadSchedule = ( technician_data ) =>
	{
		firestore
			.collection( 'schedule' )
			.doc( '1' )
			.get()
			.then( ( results ) =>
			{
				if( results.exists )
				{
					const	schedules	= results.data();
					let		rows		= [];
					let		id			= 1;

					for( const uuid in schedules )
					{
						const schedule			= schedules[uuid];
						const found_technician	= findTechnician( technician_data, schedule.technician_on_call );

						if( !found_technician ){ continue; }

						rows.push( { id: id, technician_on_call: found_technician, title: found_technician.name, startDate: new Date( schedule.start_date_time * 1000 ), endDate: new Date( schedule.end_date_time * 1000 ) } );

						id += 1;
					}

					setData( rows );

					context.current.update( LOADING, false );
				}

			} )
			.catch
			(
				function( error )
				{
					setData( [] );
					context.current.update( LOADING, false );
					console.log( error );
				}
			);
	};

	const commitChanges = ( { added, changed, deleted } ) =>
	{
		let newData = data;

		if( ( added ) && ( added.technician_on_call ) )
		{
			const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;

			newData = [ ...data, { id: startingAddedId, ...added } ];
		}

		if( ( changed ) && ( changed.technician_on_call ) )
		{
			newData = data.map( appointment => ( changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment ) );
		}

		// Set title to technician ...
		for( let i = 0; i < newData.length; i++ )
		{
			let entry = newData[i];

			entry.title 	= entry.technician_on_call.name;
			entry.allDay 	= false;
		}

		if( deleted !== undefined )
		{
			setDeletedAppointmentId( deleted );
			toggleConfirmationVisible();
		}

		setData( newData );
		setAddedAppointment( {} );

		if( ( ( added ) && ( added.technician_on_call ) ) ||
			( ( changed ) && ( changed.technician_on_call ) ) )
		{
			saveScheduleData( newData );
		}
	};

	const saveScheduleData = ( newData ) =>
	{
		let savedData = [];

		for( let i = 0; i < newData.length; i++ )
		{
			let entry = newData[i];

			savedData.push( { technician_on_call: entry.technician_on_call.id, phone_number: entry.technician_on_call.phone_number, start_date_time: getEpoch( entry.startDate ), end_date_time: getEpoch( entry.endDate ) } );
		}

		firestore
			.collection( 'schedule' )
			.doc( '1' )
			.set( { ...savedData } )
			.then( () =>
			{
			} )
			.catch
			(
				function( error )
				{
					console.log( error );
				}
			);
	};

	const onAddedAppointmentChange = ( addedAppointment ) =>
	{
		setAddedAppointment( addedAppointment );

		if( editingAppointment !== undefined )
		{
			setPreviousAppointment( editingAppointment );
		}

		setEditingAppointment( undefined );
		setIsNewAppointment( true );
	};

	const commitDeletedAppointment = () =>
	{
		const nextData = data.filter( appointment => appointment.id !== deletedAppointmentId );

		setData( nextData );
		setDeletedAppointmentId( null );

		toggleConfirmationVisible();

		saveScheduleData( nextData );
	};

	const onCancelAppointment = () =>
	{
		if( isNewAppointment )
		{
			setEditingAppointment( previousAppointment );
			setIsNewAppointment( false );
		}
	};

	const appointmentForm = connectProps( GoTurboAppointmentForm, () =>
		{
			const currentAppointment = data.filter( appointment => editingAppointment && appointment.id === editingAppointment.id )[0] || addedAppointment;

			return {
				technicians:				technicians,
				visible:					editingFormVisible,
				appointmentData:			currentAppointment,
				commitChanges:				commitChanges,
				visibleChange:				toggleEditingFormVisibility,
				onEditingAppointmentChange:	onEditingAppointmentChange,
				cancelAppointment:			onCancelAppointment
			};
		} );

	return (
		<Paper square>
			<div style={ { padding: 25 } }>
				<Paper style={ { padding: 25 } }>
					<Scheduler data={ data }>
						<ViewState currentDate={ currentDate } onCurrentDateChange={ currentDateChange } defaultCurrentViewName="Month"/>
						<EditingState onCommitChanges={ commitChanges } onEditingAppointmentChange={ onEditingAppointmentChange } onAddedAppointmentChange={ onAddedAppointmentChange }/>
						<WeekView startDayHour={ 0 } endDayHour={ 24 }/>
						<MonthView/>
						<Toolbar/>
						<DateNavigator/>
						<TodayButton/>
						<ViewSwitcher/>
						<Appointments/>
						<AppointmentTooltip showCloseButton showOpenButton/>
						<AppointmentForm overlayComponent={ appointmentForm } visible={ editingFormVisible } onVisibilityChange={ toggleEditingFormVisibility }/>
					</Scheduler>
					<Dialog open={ confirmationVisible } onClose={ toggleConfirmationVisible }>
						<DialogTitle>Delete Schedule</DialogTitle>
						<DialogContent>
							<DialogContentText>Are you sure you want to delete this schedule?</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={ toggleConfirmationVisible } color="primary" variant="outlined">Cancel</Button>
							<Button onClick={ commitDeletedAppointment } color="secondary" variant="outlined">Delete</Button>
						</DialogActions>
					</Dialog>
				</Paper>
			</div>
		</Paper>
	);
}
