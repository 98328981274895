import React from "react";
import {Paper, Tab, Tabs} from "@mui/material";
import TechnicianListing from "./technicianListing";
import TechnicianAddEdit from "./technicianAddEdit";

export default function Technicians()
{
    const [value, setValue] = React.useState( 0 );
    const [id, setId]       = React.useState( null );
    const handleChange      = ( event, newValue ) => { setId( null ); setValue( newValue ); };

    function tabProps( index )
    {
        return { id: `tab-${ index }` };
    }

    function onEditTechnician( recordID )
    {
        setId( recordID );
        setValue( 1 );
    }

    function onAddEditComplete()
    {
        setId( null );
        setValue( 0 );
    }

    return (
        <Paper square>
            <Tabs value={ value } onChange={ handleChange }>
                <Tab label="Technician Listing" { ...tabProps( 0 ) } />
                <Tab label="Add / Edit Technician" { ...tabProps( 1 ) } />
            </Tabs>
            { value === 0 && <TechnicianListing onEditTechnician={ onEditTechnician } /> }
            { value === 1 && <TechnicianAddEdit id={ id } onAddEditComplete={ onAddEditComplete } /> }
        </Paper>
    );
}
