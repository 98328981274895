import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useViewport } from "../services/viewportService";

const FieldCheckboxWithLabel = props =>
{
	const isMobile = useViewport().width < 640;
	const useStyles = makeStyles( theme => ({
		root: {
			borderRadius: 4,
			marginLeft: -9,
			paddingRight: 10,
			width: isMobile ? 250 : '100%',
			marginTop: 4,
			marginBottom: 4
		}
	}) );

	const classes = useStyles();

	return (
		<FormControlLabel
			control={
				<Checkbox name={ props.id }
						  id={ props.id }
						  size={ props.size ? props.size : 'medium' }
						  error={ props.errors[props.id] && props.touched[props.id] }
						  checked={ props.values && props.values[props.id] && props.values[props.id] !== null ? props.values[props.id] : false }
						  onChange={ props.onChange }
						  style={ props.style ? props.style : { margin: 4 } }/> }
			label={ props.label }
			size={ props.size ? props.size : 'medium' }
			classes={ classes }
		/>
	)
}

export default FieldCheckboxWithLabel;
