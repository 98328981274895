import { useContext, useEffect, useRef, useState } from "react";
import {Paper} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import { ARE_YOU_SURE_DELETE, DELETE_RECORD, formatPhoneNumber, NO, PAGE_SIZE, presentDialogFailed, presentDialogSuccess, ROWS_PER_PAGE_OPTIONS, SUCCESSFULLY_DELETED, UNSUCCESSFULLY_DELETED, YES } from "../../constants";
import ButtonDelete from "../buttonDelete";
import ButtonEdit from "../buttonEdit";
import {DialogContext} from "../../services/dialogService";
import { useFirestore } from "reactfire";

const TechnicianListing = props =>
{
    const [rows, setRows]       = useState( [] );
	const firestore				= useFirestore();
    const dialogContext         = useContext( DialogContext );
    const onEditTechnician		= ( row ) => { props.onEditTechnician( row.id ); };
    const onDeleteTechnician	= ( row ) => { dialogContext.presentDialog( true, DELETE_RECORD, ARE_YOU_SURE_DELETE, NO, () => {}, YES, onDeleteTechnicianPositive, { id: row.id }, null ); };
	const columns               = [
                                    { field: "edit", filterable: false, headerName: ' ', width: 60, align: 'center', disableColumnMenu: true, disableClickEventBubbling: true, renderCell: ( params ) => { return <ButtonEdit onClick={ () => onEditTechnician( params.row ) } /> ; } },
                                    { field: "delete", filterable: false, headerName: ' ', width: 60, align: 'center', disableColumnMenu: true, disableClickEventBubbling: true, renderCell: ( params ) => { return <ButtonDelete onClick={ () => onDeleteTechnician( params.row ) } /> ; } },
									{ field: 'full_name', headerName: 'Full Name', flex: 1 },
									{ field: 'phone_number', headerName: 'Phone Number', width: 200, valueFormatter: ( { value } ) => { return formatPhoneNumber( value ); } }
                                ];

	useEffect( () => { getTechnicians(); }, [] );

	const getTechnicians = () =>
	{
		firestore
			.collection( 'technicians' )
			.get()
			.then( ( results ) =>
			{
				let technicians = [];

				for( let i = 0; i < results.docs.length; i++ )
				{
					const technician = results.docs[i].data();

					technicians.push( { ...technician, id: results.docs[i].id } );
				}

				technicians.sort( ( a, b ) => ( a.full_name > b.full_name ) ? 1 : -1 );

				setRows( technicians );

			} )
			.catch
			(
				function( error )
				{
					setRows( [] );
					console.log( error );
				}
			);
	};

	const onDeleteTechnicianPositive = ( data ) =>
	{
		firestore
			.collection( 'technicians' )
			.doc( data.id )
			.delete()
			.then( ( results ) =>
			{
				presentDialogSuccess( dialogContext, SUCCESSFULLY_DELETED, () => { getTechnicians(); } );
			} )
			.catch
			(
				function( error )
				{
					presentDialogFailed( dialogContext, UNSUCCESSFULLY_DELETED, null );
				}
			);
	};

    return (
        <div style={ { height: 500, width: '100%', padding: 25 } }>
            <Paper style={ { height: '100%', width: '100%' } }>
                <DataGrid rows={ rows } columns={ columns } getRowId={ ( row ) => row.id } pageSize={ PAGE_SIZE } rowsPerPageOptions={ ROWS_PER_PAGE_OPTIONS } disableSelectionOnClick/>
            </Paper>
        </div>
    );
}

export default TechnicianListing;
