import {IconButton} from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {green} from "@mui/material/colors";

const ButtonEdit = props => {
    return (
        <IconButton onClick={ props.onClick }>
            <EditOutlinedIcon style={ { color: green.A700 } }/>
        </IconButton>
    )
}

export default ButtonEdit;
