import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material";
import { BACKGROUND_COLOR_ALT, PRIMARY_COLOR, RED_COLOR } from "./constants";

const theme = createTheme( {
    typography: {
        fontFamily: [
            'Open Sans'
        ].join(','),
    },
    palette: {
        mode: 'dark',
        primary: {
            main: PRIMARY_COLOR,
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
		background: {
			default: '#303030',
			paper: '#303030'
		}
    },
    overrides: {
    	MuiPaper: {
            root: {
				backgroundColor: BACKGROUND_COLOR_ALT
            }
        },
        MuiInputBase: {
            root: {
                fontWeight: 700,
                backgroundColor: '#fff',
                color: BACKGROUND_COLOR_ALT,
				"&.Mui-disabled": {
					color: BACKGROUND_COLOR_ALT
				}
            }
        },
        MuiOutlinedInput: {
            input: {
                paddingTop: '22px',
                paddingBottom: '14px'
            },
            notchedOutline: {
                borderColor: '#fff',
                color: BACKGROUND_COLOR_ALT
            },
            root: {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: 'rgba(0,0,0,0)',
                    fontWeight: 700,
                    color: BACKGROUND_COLOR_ALT
                }
            }
        },
        MuiFormLabel: {
            root: {
                color: BACKGROUND_COLOR_ALT,
                fontWeight: 700,
                "&.Mui-focused": {
                    color: '#fff',
                    fontWeight: 700
                }
            }
        },
        MuiInputLabel: {
            outlined: {
                "&.MuiInputLabel-shrink": {
                    color: BACKGROUND_COLOR_ALT,
                    fontWeight: 700,
                    transform: 'translate(14px, 1px) scale(0.75)',
                    backgroundColor: 'rgba(0,0,0,0)'
                }
            },
            formControl: {
                color: BACKGROUND_COLOR_ALT,
                fontWeight: 700
            }
        },
        MuiAutocomplete: {
            popupIndicator: {
                color: BACKGROUND_COLOR_ALT
            },
            clearIndicator: {
                color: BACKGROUND_COLOR_ALT
            }
        },
        MuiDataGrid: {
            root: {
                '& .MuiDataGrid-colCellTitle': {
                    fontWeight: 700
                },
				'& .MuiDataGrid-cell--textLeft': {
					paddingLeft: 17
				}
            }
        },
        MuiTabs: {
            flexContainer: {
                backgroundColor: RED_COLOR
            }
        },
        MuiTab: {
            wrapper: {
                fontWeight: 700
            }
        },
        MuiTablePagination: {
            select: {
                backgroundColor: BACKGROUND_COLOR_ALT
            },
            input: {
                backgroundColor: BACKGROUND_COLOR_ALT
            }
        },
        MuiSelect: {
            select: {
                fontWeight: 400,
                "&:not([multiple]) option": {
                    backgroundColor: BACKGROUND_COLOR_ALT,
                    fontWeight: 400,
                    color: '#fff'
                },
                "&:not([multiple]) option:checked": {
                    backgroundColor: '#231f20 !important'
                }
            }
        },
        MuiDataGridFilterForm: {
            columnSelect: {
                "& label": {
                    color: '#fff',
                    fontWeight: 400
                },
                "& select": {
                    fontSize: 14
                }
            },
            operatorSelect: {
                display: 'none'
            },
            filterValueInput: {
                marginLeft: 5,
                "& label": {
                    color: '#fff',
                    fontWeight: 400,
                },
                "& input": {
                    fontWeight: 400,
                    paddingLeft: 5,
                    fontSize: 14
                },
                "& select": {
                    fontSize: 14
                },
                "& select option:nth-child(1)": {
                    display: 'none'
                }
            }
        }
    }
} );

export default theme;
