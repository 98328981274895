import ButtonNeutral from "../buttonNeutral";
import { Formik, Form } from 'formik';
import { Paper } from "@mui/material";
import FieldText from "../fieldText";
import { useContext, useEffect, useState } from "react";
import { useFirestore } from "reactfire";
import ButtonPositive from "../buttonPositive";
import { OK, OPERATION_FAILED, OPERATION_SUCCESSFUL, SUCCESSFULLY_ADDED, SUCCESSFULLY_UPDATED, THERE_WAS_A_PROBLEM } from "../../constants";
import { DialogContext } from "../../services/dialogService";

const TechnicianAddEdit = props =>
{
	const firestore						= useFirestore();
	const dialogContext					= useContext( DialogContext );
	const [technician, setTechnician]	= useState( { } );
	const onCancel						= () => { props.onAddEditComplete(); };

	useEffect( () =>
	{
		if( !props.id ){ return; }

		firestore
			.collection( 'technicians' )
			.doc( props.id )
			.get()
			.then( ( results ) =>
			{
				if( results.exists )
				{
					setTechnician( results.data() );
				}

			} )
			.catch
			(
				function( error )
				{
					console.log( error );
				}
			);

	}, [ props.id ] )

	const onSubmit = ( values, actions ) =>
	{
		if( ( !values.phone_number ) || ( !values.full_name ) )
		{
			dialogContext.presentDialog( true, OPERATION_FAILED, 'All fields are required', null, null, OK, null, null, null );
			actions.setSubmitting( false );
			return;
		}

		if( ( values.phone_number.length === 0 ) || ( values.full_name.length === 0 ) )
		{
			dialogContext.presentDialog( true, OPERATION_FAILED, 'All fields are required', null, null, OK, null, null, null );
			actions.setSubmitting( false );
			return;
		}

		const phone_number = values.phone_number.replace( /\D/g, '' );

		if( ( phone_number.length === 0 ) || ( phone_number.length !== 10 ) )
		{
			dialogContext.presentDialog( true, OPERATION_FAILED, 'Invalid phone number, please enter a 10 digit number.', null, null, OK, null, null, null );
			actions.setSubmitting( false );
			return;
		}

		if( props.id )
		{
			firestore
				.collection( 'technicians' )
				.doc( props.id )
				.set( values, { merge: true } )
				.then( () =>
				{
					actions.setSubmitting( false );

					dialogContext.presentDialog( true, OPERATION_SUCCESSFUL, SUCCESSFULLY_UPDATED, null, null, OK, () => { props.onAddEditComplete(); }, null, null );

				} )
				.catch
				(
					function( error )
					{
						actions.setSubmitting( false );

						dialogContext.presentDialog( true, OPERATION_FAILED, THERE_WAS_A_PROBLEM, null, null, OK, null, null, null );

						console.log( error );
					}
				);
		}
		else
		{
			firestore
				.collection( 'technicians' )
				.add( values )
				.then( () =>
				{
					actions.setSubmitting( false );

					dialogContext.presentDialog( true, OPERATION_SUCCESSFUL, SUCCESSFULLY_ADDED, null, null, OK, () => { props.onAddEditComplete(); }, null, null );

				} )
				.catch
				(
					function( error )
					{
						actions.setSubmitting( false );

						dialogContext.presentDialog( true, OPERATION_FAILED, THERE_WAS_A_PROBLEM, null, null, OK, null, null, null );

						console.log( error );
					}
				);
		}
	};

	return (
		<div style={ { padding: 25 } }>
			<Paper style={ { padding: 25 } }>
				<Formik enableReinitialize initialValues={ technician } onSubmit={ onSubmit }>
					{ ( { values, submitForm, isSubmitting, touched, errors, handleChange } ) => (
						<Form>
							<FieldText id="full_name" label="Full Name" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
							<FieldText id="phone_number" label="Phone Number" values={ values } errors={ errors } touched={ touched } onChange={ handleChange }/>
							<br/>
							<br/>
							<ButtonNeutral label="Cancel" disabled={ isSubmitting } onClick={ onCancel }/>
							<ButtonPositive label="Save" disabled={ isSubmitting } onClick={ submitForm }/>
						</Form>
					) }
				</Formik>
			</Paper>
		</div>
	);
}

export default TechnicianAddEdit;
