import { IconButton } from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { red } from "@mui/material/colors";

const ButtonDelete = props =>
{
	return (
		<IconButton onClick={ props.onClick }>
			<DeleteForeverOutlinedIcon style={ { color: red.A700 } }/>
		</IconButton>
	)
}

export default ButtonDelete;
