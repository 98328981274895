import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StateProvider } from "./services/stateService";
import { ViewportProvider } from "./services/viewportService";
import { DialogProvider } from "./services/dialogService";
import Index from "./pages";
import Administration from "./pages/administration";

export default function App()
{
	return (
		<LocalizationProvider dateAdapter={ AdapterMoment }>
			<StateProvider>
				<ViewportProvider>
					<DialogProvider>
						<BrowserRouter>
							<Switch>
								<Route path="/administration" children={ <Administration/> }/>
								<Route path="/" children={ <Index/> }/>
							</Switch>
						</BrowserRouter>
					</DialogProvider>
				</ViewportProvider>
			</StateProvider>
		</LocalizationProvider>
	);
}
