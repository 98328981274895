import React, { useContext, useEffect, useRef } from "react";
import {
	AppBar, Box,
	CssBaseline,
	Divider,
	Drawer,
	Hidden,
	IconButton, List, ListItem, ListItemIcon, ListItemText,
	Toolbar
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles, useTheme } from '@mui/styles';
import Users from "../components/users/users";
import ButtonNeutral from "../components/buttonNeutral";
import {StateContext} from "../services/stateService";
import {withRouter} from "react-router-dom";
import { BACKGROUND_COLOR_ALT } from "../constants";
import Groups from "../components/groups/groups";
import GeotabService from "../services/geotabService";
import { preloadFirestore, useFirebaseApp } from "reactfire";
import ServiceRequests from "../components/serviceRequests/serviceRequests";
import Settings from "../components/settings/settings";
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SmsIcon from '@mui/icons-material/Sms';
import firebase from "firebase";
import Technicians from "../components/technicians/technicians";
import ServiceStatuses from "../components/serviceStatuses/serviceStatuses";
import FlagIcon from '@mui/icons-material/Flag';
import Schedule from "../components/schedule/schedule";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Administration = props =>
{
	const drawerWidth = 240;

	const useStyles = makeStyles( ( theme ) => ( {
		root: {
			display: 'flex',
		},
		drawer: {
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				flexShrink: 0,
			},
		},
		appBar: {
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${drawerWidth}px)`,
				marginLeft: drawerWidth,
			},
			backgroundColor: BACKGROUND_COLOR_ALT
		},
		menuButton: {
			marginRight: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				display: 'none',
			},
		},
		toolbar: theme.mixins.toolbar,
		drawerPaper: {
			width: drawerWidth,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
	}));

	const context           			= useRef( useContext( StateContext ) );
	const firebaseApp					= useFirebaseApp();
	const classes						= useStyles();
	const theme							= useTheme();
	const [mobileOpen, setMobileOpen]	= React.useState( false );
	const handleDrawerToggle			= () => { setMobileOpen( !mobileOpen ); };
	const container						= window !== undefined ? () => window.document.body : undefined;
	const [menuItems, setMenuItems]		= React.useState( [] );
	const [activeItem, setActiveItem]	= React.useState( null );

	const drawer = (
		<div>
			<div className={ classes.toolbar }/>
			<Divider/>
			<List>
				{ menuItems.map( ( item, index ) => (
					<ListItem button key={ item.name } onClick={ () => onMenuItemSelected( index ) } selected={ !!( activeItem && activeItem.name === item.name ) } >
						<ListItemIcon>{ item.icon }</ListItemIcon>
						<ListItemText primary={ item.name }/>
					</ListItem>
				) ) }
			</List>
		</div>
	);

	const onMenuItemSelected = ( index ) => { setActiveItem( menuItems[index] ); }

	const onLogout = () =>
    {
		firebase.auth().signOut().then( () =>
		{
			props.history.push( "/" );
		}
		).catch( ( error ) =>
		{
			props.history.push( "/" );
		} );
    };

	const buildMenuItems = ( user ) =>
	{
		let items = [];

		items.push( { 'name': 'Global Settings', 'component': <Settings />, 'icon': <SettingsIcon /> } );

		if( user.email )
		{
			items.push( { 'name': 'Vehicle Groups', 'component': <Groups />, 'icon': <LocalShippingIcon /> } );
			items.push( { 'name': 'Users', 'component': <Users />, 'icon': <PeopleIcon /> } );
			items.push( { 'name': 'Technicians', 'component': <Technicians/>, 'icon': <PeopleAltIcon/> } );
		}

		if( user.email )
		{
			items.push( { 'name': 'Service Statuses', 'component': <ServiceStatuses/>, 'icon': <FlagIcon/> } );
		}

		items.push( { 'name': 'Service Requests', 'component': <ServiceRequests/>, 'icon': <SmsIcon/> } );

		if( user.email )
		{
			items.push( { 'name': 'On Call Schedule', 'component': <Schedule/>, 'icon': <CalendarMonthIcon/> } );
		}

		setMenuItems( items );
	};

	useEffect( () =>
	{
		const user = firebase.auth().currentUser;

		if( !user )
		{
			props.history.push( "/" );
			return;
		}

		buildMenuItems( user );

		GeotabService( context.current ).authenticate();

		preloadFirestore( {
			firebaseApp: firebaseApp,
			setup: async firestore =>
			{
				await firestore().enablePersistence();
			}
		} );

	}, [ firebaseApp ] )

	return (
		<div className={ classes.root }>
			<CssBaseline/>
			<AppBar position="fixed" className={ classes.appBar }>
				<Toolbar>
					<IconButton color="inherit" edge="start" onClick={ handleDrawerToggle } className={ classes.menuButton }>
						<MenuIcon/>
					</IconButton>
					<Box style={ { flexGrow: 1, lineHeight: 0 } }>
						<img src={ '../images/goturbo-logo-rev.png' } height={ 42 } alt="" />
					</Box>
					<ButtonNeutral label="Logout" onClick={ onLogout } />
				</Toolbar>
			</AppBar>
			<nav className={classes.drawer} >
				<Hidden smUp implementation="css">
					<Drawer container={ container } variant="temporary" anchor={ theme.direction === 'rtl' ? 'right' : 'left' } open={ mobileOpen } onClose={ handleDrawerToggle } classes={ { paper: classes.drawerPaper } } ModalProps={ { keepMounted: true } }>
						{ drawer }
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer classes={ { paper: classes.drawerPaper } } variant="permanent" open>
						{ drawer }
					</Drawer>
				</Hidden>
			</nav>
			<main className={ classes.content }>
				<div className={ classes.toolbar }/>
				{ activeItem && activeItem.component }
			</main>
		</div>
	);
}

export default withRouter( Administration );
