import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const FieldAutocompleteNonForm = ( props ) =>
{
	const onChange = ( event, newValue ) =>
	{
		if( props.onChange )
		{
			props.onChange( props.id, newValue );
		}
	};

	return (
        <Autocomplete
            name={ props.id }
            id={ props.id }
            size={ props.size ? props.size : 'medium' }
            style={ props.style ? props.style : { width: '100%', display: 'inline-flex' } }
            value={ props.value }
            onChange={ onChange }
			isOptionEqualToValue={ ( option, value ) => option[props.option_key] === value[props.option_key] }
            getOptionLabel={ ( option ) => option[props.option_name] }
            options={ props.options }
            renderInput={ ( params ) => (
                <TextField
                    { ...params }
                    label={ props.label }
                    variant="outlined"/>
            ) }
        />
    );
}

export default FieldAutocompleteNonForm;
